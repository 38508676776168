<template>
  <div class="about">
    <h2>Who I am</h2>
    <div class="card">
      <p>I'm Liam, also known as Whiskee or WhiskeeDev. I started studying development as a hobby when I got my first computer around 12, first learning LUA to create script for <a href="https://github.com/dan200/ComputerCraft">ComputerCraft</a> and, later, Addons for Garry's Mod.</p>
      <br>
      <p>At 16 I started an apprenticeship as a Web Developer, and a year later I was the sole Full-stack Developer for our company's in-house CRM system.</p>
      <p>At 18 I left that role to work as a Frontend Web Developer, with the intention of fine tuning my Javascript, Css, Scss as well as learn UI/UX.</p>
      <p>At 20 I moved on from that role to become a Software Engineer for a much bigger company, working on their ecommerce platform, the intention in this role being to strengthen my backend knowledge as well as learn more managerial type skills.</p>
      <br>
      <p>I have a background in Javascript and PHP, using frameworks from Vue.js to Laravel. I have created several backend type Rest APIs that run entirely in Node.js and I intend to expand.</p>
      <p>I've also spent time recently working on my Level design skills in Hammer (1 and 2), as well as strengthen my C# skills in preperation for <a href="https://sbox.facepunch.com/">S&box</a>.</p>
    </div>
    <br>
    <h2>Feature Project(s)</h2>
    <div class="card">
      <h1>OvO</h1>
      <p><a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2189395411">One Versus One</a>, or OvO for short, was a Gmod gamemode me and my brother worked on during covid lockdown to blow off some steam. We didn't spend long on it, and it ended up abandoned once we returned to normal(ish) lives.</p>
      <p>With the development of S&Box, we intend to revive the project properly, making a fast pace PvP environment, mainly designed for one on one environments where you can just chill, shoot the shit with a mate, and honestly have an all around good time.</p>
      <p>During development, we introduced some daft bugs, that we later reworked into features. One of which was the <code>Spectre Ability</code>, which allowed you to borrow power from the afterlife granting you temporary immunity and increased speed, in exchange you give up the ability to use your weapons while in this ability and your health is slowly drained.</p>
      <p>You can <a href="https://forum.facepunch.com/t/245099">Follow development here</a>, or keep an eye on my <a href="https://twitter.com/WhiskeeDev">twitter</a>.</p>
      <div
        v-for="(media, index) in getMedia('wsky_ovo_remake')"
        :key="media.src + index"
        :class="index % 2 ? 'even' : 'odd'"
        style="display: inline"
      >
        <a v-if="media.type === 'image'" href="#" @click="previewSrc = media.src">
          <img :src="media.src">
        </a>
      </div>
    </div>
    <div class="card" style="text-align: right">
      <h1 style="display: inline-flex">
        Jaxbot
      </h1>
      <p>JaxBot is a discord bot purpose-built for <a href="https://tophat-network.co.uk">TopHat Network™</a>.</p>
      <p>The intention for Jax, is for him to connect to all of our servers/games (we refer to these as our "services"), and link them together via one web app. The goal being that rather feeling like you're hopping from one server to another, you're merely changing scenery.</p>
      <p>
        At the moment, Jax can record users, warnings given by staff, applications, and provide detailed logs on its actions. It’s powered by
        Node.js and uses AWS RDS for data storage.
      </p>
      <p>
        It also has a HTTPS server, with a signed SSL certificate, which is used as a REST API for a (early access) web
        application that will be available to our users and staff (staff having options to make actions from the web app, rather
        than in discord).
      </p>
      <p>
        You can find more info, and check him out yourself, <a href="https://tophat-network.co.uk/jax">here</a>.
      </p>
      <p>
        <em>NOTE: A Discord account and being a member of the TopHat Network™ Guild is necessary for access to the web app.</em>
      </p>
    </div>
    <image-preview v-model="previewSrc" />
  </div>
</template>
<script>
import ImagePreview from '@/components/ImagePreview'

export default {
  components: { ImagePreview },
  data () {
    return {
      previewSrc: null,
      imageSections: {
        'wsky_ovo_remake': {
          count: 7,
          folder: 'wsky_ovo_remake'
        }
      }
    }
  },
  methods: {
    getMedia (id) {
      if (!id) return null

      let media = []
      for (let imageId = 1; imageId <= this.imageSections[id].count; imageId++) {
        // console.log(`./assets/images/${this.imageSections[id].folder}/${imageId}.jpg`)
        media.push({
          type: 'image',
          src: require(`@/assets/images/${this.imageSections[id].folder}/${imageId}.jpg`)
        })
      }

      if (this.imageSections[id].videos) {
        this.imageSections[id].videos.forEach(videoId => {
          media.push({
            type: 'video',
            src: require(`@/assets/images/${this.imageSections[id].folder}/${videoId}.mp4`),
            thumb: require(`@/assets/images/${this.imageSections[id].folder}/${videoId}.jpg`)
          })
        })
      }

      return media
    }
  }
}
</script>
